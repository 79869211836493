export function checkForLoadedComponent(component, name) {
  if (!component || component.clientHeight === 0) {
    const myInterval = setInterval(() => {
      if (component && component.clientHeight > 0) {
        sendLoadedEvent(component, name)
        clearInterval(myInterval)
      }
      setTimeout(() => {
        clearInterval(myInterval)
      }, 5000)
    }, 100)
  } else {
    sendLoadedEvent(component, name)
  }
}

export function findTarget(selector) {
  if (selector) {
    let selectors = selector.split('|')
    let target = document.querySelector(selectors[0])

    if (target) {
      for (let i = 1; i < selectors.length; i++) {
        const newTarget = target.querySelector(selectors[i])
          ? target.querySelector(selectors[i])
          : target.shadowRoot
          ? target.shadowRoot.querySelector(selectors[i])
          : null
        if (newTarget) {
          target = newTarget
        }
      }
    }
    return target
  }
}

function sendLoadedEvent(component, name) {
  const event = new CustomEvent('livebyComponentLoaded', {
    detail: { height: component.clientHeight, name }
  })
  window.dispatchEvent(event)
}

export function setLoaded(element, name) {
  checkForLoadedComponent(element, name)
}

// list of components that will trigger the loadable events
export const loadableComponents = [
  'about',
  'agent-insights',
  'amenities',
  'community',
  'custom-content',
  'demographics',
  'directory-listing',
  'directory-search',
  'external-link',
  'hero',
  'hero-v2',
  'image-gallery',
  'interested-in',
  'interested-in-v2',
  'market-trends',
  'map',
  'map-yelp',
  'nav',
  'new-listings',
  'parent-page',
  'properties',
  'reviews',
  'send-message-inline',
  'schools',
  'up-to-date'
]
