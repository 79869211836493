module.exports =
  typeof Object.assign === 'function'
    ? Object.assign
    : function (target) {
        if (!target) {
          throw new TypeError('Cannot convert undefined or null to object')
        }

        for (let i = 1, len = arguments.length; i < len; i++) {
          const source = arguments[i]
          if (source) {
            for (const key in source) {
              if (source.hasOwnProperty(key)) {
                target[key] = source[key]
              }
            }
          }
        }
        return target
      }
