/* eslint-disable no-var */

var bbox = require('@turf/bbox')
/**
 * liveby.js
 * =========
 * A promise-based wrapper for the LiveBy APIs which can be used on the client
 * or server.
 *
 * > Developer note: This library is designed to be included in both browser
 * and server contexts. When maintaining or adding functionality, be sure to
 * use libraries that are usable in either context. While this file will be
 * "babelified" on the client, it will **not** be transformed on the server.
 * Developers should take caution to only use ES6 features supported in Node v4.
 *
 * Usage
 * -----
 *
 * Install with npm:
 *
 * ```sh
 * npm install --save LiveBy/liveby
 * ```
 *
 * Then use like a normal npm module:
 *
 * ```js
 * var LiveBy = require('liveby')
 *
 * // Create a new API instance
 * var liveby = LiveBy({version: 1})
 *
 * // Make some queries
 * liveby.geoid(31).then(
 *   result => console.log(result.data),
 *   err => console.error(err)
 * )
 * ```
 */
module.exports = LiveBy

var stringify = require('qs/lib/stringify') // Save 8K by excluding .parse
var fetch = require('isomorphic-fetch')
var assign = require('./assign') // Save 40K by using a lighter `assign` shim

var endpoints = (LiveBy.endpoints = {
  auth: {
    create: '_/auth/create',
    login: '_/auth/login',
    logout: '_/auth/logout'
  },
  brokerage: 'brokerage',
  events: 'events',
  geoid: '_/geoid',
  national: 'national',
  neighborhood: 'neighborhood',
  neighborhoods: 'neighborhoods/properties',
  neighborhoodGeoJSON: 'neighborhoods/geojson',
  neighborhoodPolyline: 'neighborhood/polyline',
  //  matches: 'matches',  // Not currently used
  notify: 'notify',
  factors: 'factors',
  transitScore: '_/transit-score',
  geocode: '_/geocode',
  route: '_/route',
  distance: '_/route/table',
  user: 'user',
  places: 'places',
  propertiesAttributeAggregator: 'properties-attribute-aggregator',
  matchScore: {
    proximity: '_/match-score/proximity',
    matchResults: '_/match-score/match-results',
    neighborhoods: '_/match-score/neighborhoods'
  },
  shareMatch: '_/share',
  shareNeighborhood: '_/share-neighborhood',
  locations: 'locations',
  amenities: 'amenities',
  yelpBusinesses: 'yelp-businesses',
  yelpBusinessesMap: 'yelp-businesses/yelpMap',
  mlsInfo: 'mls-info'
})

var defaults = (LiveBy.defaults = {
  version: 1,
  endpoint: '127.0.0.1:8001',
  secure: false
})

/**
 * LiveBy({ version })
 * -------------------
 *
 * Create a new instance of the LiveBy API client with the passed options. May
 * be called with or without `new`.
 *
 * - `version` (required): A Number (or String) representing the API version to
 *   query.
 * - `endpoint`: A String representing the API endpoint to query. Should
 *   contain the hostname and port. Default is `127.0.0.1:3000` currently, but
 *   will change when released.
 *
 *   TODO (ben@liveby.co): Update with the correct API endpoint in production.
 *
 * - `secure`: A Boolean indicating whether or not to request over https.
 *   Default is `false`.
 *
 * Example:
 *
 * ```js
 * var liveby = new LiveBy({ version: 1 })
 * ```
 */
function LiveBy(options) {
  if (!(this instanceof LiveBy)) {
    return new LiveBy(options)
  }

  this.config = assign({}, defaults, options)

  var secure = this.config.secure
  var endpoint = this.config.endpoint
  // var version = this.config.version
  this.requestCache = {}

  this.root = 'http' + (secure ? 's' : '') + '://' + endpoint + '/v1/'
}

LiveBy.prototype = {
  /**
   * liveby.setCSRF(csrf)
   * This sets the csrf token for the entire API. Needed if you want to post.
   */
  setCSRF: function (csrf) {
    this.config.csrf = csrf
  },
  /**
   * ### liveby.create({fname, lname, email, brokerage})
   *
   * Create a new user account for the passed user.
   */
  create: function (query) {
    return this._fetch(
      endpoints.auth.create,
      {},
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: query
      }
    )
  },
  /**
   * ### liveby.login({email})
   *
   * Log in the user with the passed email address, and return a promise
   * that will resolve with the saved preferences and favorites from the user's
   * last session.
   */
  login: function (query) {
    return this._fetch(
      endpoints.auth.login,
      {},
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: query
      }
    )
  },
  /**
   * ### liveby.logout()
   *
   * Logging out will create a new empty session for the user
   */
  logout: function () {
    return this._fetch(endpoints.auth.logout)
  },

  /**
   * ### liveby.brokerage( identifier ).then(…)
   *
   * Return a promise that will resolve to a Brokerage query result for the
   * passed `identifier`.
   *
   * Parameters:
   *
   * - `identifier`: A String containing either the `_id` or `shortname`
   *   property for the brokerage.
   *
   * Example:
   *
   * ```js
   * var liveby = new LiveBy({version: 1})
   *
   * // Retrieve and log the brokerage information for woodsbros
   * liveby.brokerage('woodsbros').then(result => {
   *   console.log(result.data)
   * }, err => {
   *   console.error(err)
   * })
   * ```
   *
   * See the [Brokerage schema] for a complete description of the properties
   * available on the brokerage result object.
   *
   * [Brokerage schema]: https://github.com/LiveBy/API/blob/master/models/brokerage.js#6
   */
  brokerage: function (id) {
    return this._fetch(endpoints.brokerage + '/' + id)
  },
  /**
   * ### liveby.neighborhood( id ).then(…)
   *
   * Return a promise that will resolve to a Neighborhood query result for
   * passed `id`. The `data` property of the query result will be a single
   * neighborhood.
   *
   * Parameters:
   *
   * - `id`: A String containing the MongoDB `_id` of the requested
   *   Neighborhood.
   *
   * Example:
   *
   * ```js
   * var liveby = new LiveBy({version: 1})
   *
   * // Retrieve and log the name of the College View Neighborhood
   * liveby.neighborhood('56699c04dd4700202391f2fb').then(result => {
   *   console.log(result.data.properties.label)
   *   // "College View"
   * }, err => {
   *   console.error(err)
   * })
   * ```
   *
   * See the [Neighborhood schema] for a complete description of the properties
   * available on the Neighborhood result object.
   * [Neighborhood schema]: https://github.com/LiveBy/API/blob/master/models/neighborhood.js#L6
   */
  neighborhood: function (id) {
    return this._fetch(endpoints.neighborhood + '/' + id)
  },
  /**
   * ### liveby.national
   *
   * Return a promise that will resolve to a National query result for the entirety of the National collection.
   *
   */
  national: function () {
    return this._fetch(endpoints.national).then(function (result) {
      result.data = result.data[0]
      return result
    })
  },

  /**
   * ### liveby.neighborhoodGeoJSON( id ).then(…)
   *
   * Return a promise that will resolve to a Neighborhood GeoJSON result for
   * passed `id`. The `data` property of the query result will be a single
   * neighborhood, including it's GeoJSON geometry property.
   *
   * Parameters:
   *
   * - `id`: A String containing the MongoDB `_id` of the requested
   *   Neighborhood.
   *
   * Example:
   *
   * ```js
   * var liveby = new LiveBy({version: 1})
   *
   * // Retrieve and log the GeoJSON geometry for the College View Neighborhood
   * liveby.neighborhoodGeoJSON('56699c04dd4700202391f2fb').then(result => {
   *   console.log(result.data.properties.geometry)
   *   // {"type" : "Polygon", "coordinates" : [[[-88.799618, 33.462813]…]]}
   * }, err => {
   *   console.error(err)
   * })
   * ```
   *
   * See the [Neighborhood schema] for a complete description of the properties
   * available on the Neighborhood GeoJSON result object.
   */
  neighborhoodGeoJSON: function (id) {
    // Don't fetch the same neighborhood multiple times
    var req = endpoints.neighborhood + '/' + id
    if (this.requestCache[req]) {
      return this.requestCache[req]
    } else {
      return (this.requestCache[req] = this._fetch(req))
    }
  },
  /**
   * ### liveby.neighborhoods( query ).then(…)
   *
   * Return a promise that will resolve to a Neighborhood query result
   * containing Neighborhoods that matched the passed `query`. The `data`
   * property of the result will be an array of
   * [Neighborhoods][Neighborhood schema] excluding the `geometry` property.
   *
   * Parameters:
   *
   * - `query`: An optional object identifying which neighborhoods to match on
   *   The most common query properties are:
   *   - `city`: String containing the name of the city to match
   *   - `state`: String containing the two-letter postal abbreviation for the
   *     state
   *
   * Example:
   *
   * ```js
   * var liveby = new LiveBy({version: 1})
   *
   * // Retrieve and log neighborhood information for Lincoln, NE
   * liveby.neighborhoods({city: 'Lincoln', state: 'NE'}).then(result => {
   *   result.data.forEach(neighborhood => console.log(neighborhood))
   * }, err => {
   *   console.error(err)
   * })
   * ```
   *
   * See the [Neighborhood schema] for a complete description of the properties
   * available on the Neighborhood result object.
   */
  neighborhoods: function (query, page) {
    var brokerage = query && query.brokerage && query.brokerage.shortname
    if (!brokerage) {
      return Promise.reject(
        new Error('A brokerage is required for a neighborhoods query.')
      )
    }
    delete query.brokerage

    // For convenience, just query the 'properties' object.
    var q = {
      filter: { properties: query },
      brokerage: brokerage,
      page: assign(
        {
          limit: 25,
          offset: 0
        },
        page
      )
    }
    return this._fetch(endpoints.neighborhoods, q)
  },
  logError: function(clientid, error, url) {
    return fetch(
      `https://${
        process.env.REACT_APP_API_ENDPOINT
      }/v1/pages/profilesLogs`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({clientid, error, url})
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.error(`Failed to log error (${error}) for client (${clientid})`)
        console.error(err)
      })
  },
  clientDomain: function(clientid, domain) {
    return fetch(
      `https://${
        process.env.REACT_APP_API_ENDPOINT
      }/v1/pages/clientDomain?clientid=${clientid}&domain=${domain}`
    )
      .then((res) => res.json())
      .catch((err) => {
        console.error(`Failed to log domain (${domain}) for client (${clientid})`)
        console.error(err)
      })
  },
  /**
   * ### liveby.neighborhoodsGeoJSON( query ).then(…)
   *
   * Return a promise that will resolve to a Neighborhood query result
   * containing Neighborhoods that matched the passed `query`. The `data`
   * property of the result will be an array of
   * [Neighborhoods][Neighborhood schema] **including** the `geometry` and
   * `centroid` properties.
   *
   * Parameters:
   *
   * - `query`: An optional Object identifying which neighborhoods to match.
   *   The most common query properties are:
   *   - `city`: String containing the name of the city to match
   *   - `state`: The two-letter postal abbreviation for the state
   *
   * Example:
   *
   * ```js
   * var liveby = new LiveBy({version: 1})
   *
   * // Retrieve and log GeoJSON geometry for Lincoln, NE
   * liveby.neighborhoodsGeoJSON('56699c04dd4700202391f2fb').then(result => {
   *   result.data.forEach(neighborhood => console.log(neighborhood.properties.geometry))
   *   // {"type" : "Polygon", "coordinates" : [[[-88.799618, 33.462813]…]]}
   * }, err => {
   *   console.error(err)
   * })
   *
   * // Retrieve GeoJSON data for neighborhoods containing blockgroups
   * // in the state of Nebraska
   * liveby.neighborhoodsGeoJSON({blockgroups: /^31/})
   *   .then(result => {
   *     result.data.forEach(neighborhood => console.log(neighborhood.properties.geometry))
   *   })
   *   .catch(err => console.error(err))
   * ```
   *
   * See the [Neighborhood schema] for a complete description of the properties
   * available on the Neighborhood GeoJSON result object.
   */
  neighborhoodsGeoJSON: function (query) {
    var brokerage = query && query.brokerage && query.brokerage.shortname
    if (!brokerage) {
      return Promise.reject(
        new Error('A brokerage is required for a neighborhood GeoJSON query')
      )
    }
    delete query.brokerage

    // For convenience, just query the 'properties' object.
    var q = {
      filter: { properties: query },
      brokerage: brokerage,
      page: { limit: 1000 }
    }
    return this._fetch(endpoints.neighborhoodGeoJSON, q)
  },
  /**
   * ### Google places
   *
   * Returns place in the boundary requests from google.
   */
  googlePlaces: function googlePlaces(query) {
    return this._fetch(
      'google-places',
      {},
      {
        method: 'POST',
        body: query
      }
    )
  },
  /**
   * ### liveby.neighborhoodPolyline({ polyline, label })
   *
   * Returns a promise that resolves to a neighborhood with that polyline, or the closest with the label
   *
   * Parameters:
   *  - `query` : OBJECT: Query object
   *    - `query.polyline`: STRING, polyine encoding of the neighborhoods INTPTLAT, INTPTLON
   *    - `query.label`: STRING, label of the neighborhood being looked for
   */
  neighborhoodPolyline: function (query) {
    return this._fetch(
      endpoints.neighborhoodPolyline + '/' + query.polyline + '/' + query.label
    )
  },
  /**
   * ### liveby.local({lat, lng, clientid, allowZipcode } )
   *
   * Returns a neighborhood the clientid has access to.
   * if no neighborhood exists and allowZipcode is set, it will return a zipcode
   * @param {*} query
   */
  local: function (query) {
    return this._fetch('local', query)
  },
  /**
   * ### liveby.ldpPB({lat, lng, clientid, types } )
   *
   * Returns a neighborhood the clientid has access to.
   * if no neighborhood exists and allowZipcode is set, it will return a zipcode
   * @param {*} query
   */
  ldpPB: function (query) {
    return fetch(
      `https://${process.env.REACT_APP_API_ENDPOINT}/v1/pages/ldp?${stringify(
        query,
        { arrayFormat: 'repeat' }
      )}`
    )
      .then((res) => res.json())
      .catch((err) => {
        console.error('Failed to find LiveBy boundary')
        console.error(err)
      })
  },
  /**
   * ### liveby.getPageByBoundaryAndTemplate({templateId, boundaryId, clientId } )
   *
   * Returns a page the clientid has access to that has the boundaryId.
   * @param {*} query
   */
  getPageByBoundaryAndTemplate: function (query) {
    return fetch(
      `https://${
        process.env.REACT_APP_API_ENDPOINT
      }/v1/pages/get-page-by-boundary-and-template?${stringify(query, {
        arrayFormat: 'repeat'
      })}`
    )
      .then((res) => res.json())
      .catch((err) => {
        console.error('Failed to find LiveBy boundary')
        console.error(err)
      })
  },
  /**
   * ### liveby.ldpTemplate({ clientid })
   *
   * Returns a LDP template so we can check for a property listing ID selector.
   * @param {*} query
   */
  ldpTemplate: function (query) {
    return fetch(
      `https://${
        process.env.REACT_APP_API_ENDPOINT
      }/v1/pages/ldp-template?${stringify(query, { arrayFormat: 'repeat' })}`
    )
      .then((res) => res.json())
      .catch((err) => {
        console.error('Failed to find LDP Template')
        console.error(err)
      })
  },
  /**
   * ### liveby.ldpPB({ clientid, listingId, types } )
   *
   * Returns a neighborhood the clientid has access to.
   * @param {*} query
   */
  ldpByListingId: function (query) {
    return fetch(
      `https://${
        process.env.REACT_APP_API_ENDPOINT
      }/v1/pages/ldp-by-listing-id?${stringify(query, {
        arrayFormat: 'repeat'
      })}`
    )
      .then((res) => res.json())
      .catch((err) => {
        console.error('Failed to find LDP')
        console.error(err)
      })
  },
  /**
   * ### liveby.events(neighborhood)
   *
   * Return events occuring within or near the passed neighborhood.
   *
   * Parameters:
   * - `neighborhood`: An Object which is the neighborhood.
   */
  events: function (neighborhood) {
    return this._fetch(
      endpoints.events,
      {},
      {
        method: 'POST',
        body: {
          lat: neighborhood.properties.INTPTLAT,
          lng: neighborhood.properties.INTPTLON,
          geometry: neighborhood.geometry
        }
      }
    )
  },
  /**
   * ### liveby.localInsights(neighborhood)
   *
   * Return tags which describe the neighborhood based on the community's
   *  social activity
   *
   * Parameters:
   * - `neighborhood`: An Object which is the neighborhood.
   */
  localInsights: function (neighborhood) {
    var box = bbox(neighborhood)
    var options = {
      sw: box[1] + ',' + box[0],
      ne: box[3] + ',' + box[2]
    }
    return this._fetch('local-insights', options)
  },
  /**
   * ### liveby.schools(neighborhood)
   *
   * Return school information for a neighborhood.
   *
   * Parameters:
   * - `neighborhood`: An Object which is the neighborhood.
   *
   * Returns:
   *
   * - An Object with a `schools` property that is an Array of school objects
   */
  schools: function (neighborhood) {
    var options = {
      lat: neighborhood.properties.INTPTLAT,
      lng: neighborhood.properties.INTPTLON,
      state: neighborhood.properties.address.state
    }

    return this._fetch('schools', options)
  },

  /**
   * ### liveby.schoolInfo(neighborhood)
   *
   * Parameters:
   *   - `neighborhood`: A LiveBy Neighborhood(_id, properties.address.state).
   *   - `options`: An object of optional options:
   *     - `searchByCity`: Optionally, find schools for the neighborhood's city, rather than using a distance query.
   *     - `sort`: Sort results
   *     - `level`: Constrain to `level`, i.e. Elementary, Middle, High, Private
   *     - `page`: Skip to `page`
   *     - `limit`: Limit to `limit` results
   *
   * Returns schools that are near or have school boundaries that intersect the neighborhood
   */
  schoolInfo: function (neighborhood, options) {
    const query = assign(
      {
        boundary: neighborhood._id,
        format: 'expanded'
      },
      options
    )

    return this._fetch('schools/info', query)
  },

  /**
   * ### liveby.agentInsights(brokerage, neighborhood)
   *
   * Return insightful comments from real life agents
   *
   * Parameters:
   * - `brokerage`: The borkerage object.
   * - `neighborhood`: The neighborhood object.
   *
   * Returns:
   *
   * - An Object with an `insights` property that is an Array of agent insights.
   */
  agentInsights: function (brokerage, neighborhood) {
    return this._fetch('agents/insights', {
      brokerage: brokerage.shortname,
      neighborhood: neighborhood._id
    })
  },
  /**
   * liveby.propertyPhotos(id)
   */
  propertyPhotos: function (options) {
    return this._fetch(
      'properties-photos',
      {},
      {
        method: 'POST',
        body: { id: options.id, photoId: options.photoId }
      }
    )
  },
  /**
   * ### liveby.availableProperties(neighborhood)
   */
  availableProperties: function (
    neighborhood,
    offset,
    select,
    vendor,
    status,
    limit,
    types
  ) {
    if (!offset) offset = 0
    var options = {
      method: 'POST',
      body: {
        offset: offset,
        limit: limit || 200,
        select: select,
        status: status || ['Active', 'ActiveUnderContract'],
        geometry: neighborhood.geometry,
        'property.type': types || ['RES', 'MLF', 'CND'],
        vendor: vendor
      }
    }
    return this._fetch('simply-rets-service/properties', {}, options)
  },

  /**
   * ### liveby.marketStats
   */
  marketStats: function (options) {
    if (options.neighborhood) {
      options.geometry = options.neighborhood.geometry
      delete options.neighborhood
    }

    var o = {
      method: 'POST',
      body: options
    }
    return this._fetch('market-stats', {}, o)
  },
  /**
   * ### liveby.matchResults( preferences, city ).then(…)
   *
   * Return a promise that will resolve to an array of match results for each
   * Neighborhood in the city (urbanarea) ordered by the highest match score
   * first.
   *
   * Parameters:
   *
   * - `preferences`: The state object containing the user's preferences
   * - `city`: The GEOID of the urban area we are interested in
   *
   * Example:
   *
   * ```js
   * var liveby = new LiveBy({version: 1})
   *
   * // Retrieve match results for the city of Lincoln
   * liveby.matchResults(state.preferences, state.city)
   *   .then(result => console.log(result))
   *   .catch(err => console.error(err))
   * ```
   *
   * [Neighborhoods]: https://github.com/LiveBy/API/blob/master/models/neighborhood.js
   */
  matchResults: function (preferences, query, page) {
    var brokerage = query && query.brokerage && query.brokerage.shortname
    if (!brokerage) {
      return Promise.reject(
        new Error('A brokerage is required for a match result query.')
      )
    }
    delete query.brokerage

    var q = {
      preferences: preferences,
      filter: { properties: query },
      brokerage: brokerage,
      page: assign(
        {
          limit: 25,
          offset: 0
        },
        page
      )
    }
    return this._fetch(endpoints.matchScore.matchResults, q)
  },

  /**
   * ### liveby.matchNeighborhoods(preferences, neighborhoods, query).then(…)
   *
   * Returns the same results as `liveby.matchResults`, but queried by neighborhood ids
   *
   *
   * Parameters:
   *
   * - `preferences`: The state object containing the user's preferences
   * - `query`:
   * -    `neighborhoods`: An array of neighborhoods to get matches for
   * -    `brokerage`: Brokerage to filter by
   *
   * Example:
   *
   * ```js
   * var liveby = new LiveBy({version: 1})
   *
   * // Retrieve match results for the city of Lincoln
   * liveby.matchResults(state.preferences, state.user.favorites)
   *   .then(result => console.log(result))
   *   .catch(err => console.error(err))
   * ```
   *
   * [Neighborhoods]: https://github.com/LiveBy/API/blob/master/models/neighborhood.js
   *
   */
  matchNeighborhoods: function (preferences, query, page) {
    var brokerage = query && query.brokerage && query.brokerage.shortname
    if (!brokerage) {
      return Promise.reject(
        new Error('A brokerage is required for a match result query.')
      )
    }
    delete query.brokerage

    var neighborhoods = query && query.neighborhoods
    if (!neighborhoods) {
      return Promise.reject(
        new Error(
          'A list of Neighborhoods is required. Please use LiveBy#matchResults for a list of all neighborhoods.'
        )
      )
    }
    delete query.neighborhoods

    var q = {
      preferences: preferences,
      filter: { properties: query },
      neighborhoods: neighborhoods,
      brokerage: brokerage,
      page: assign(
        {
          limit: 25,
          offset: 0
        },
        page
      )
    }
    return this._fetch(endpoints.matchScore.neighborhoods, q)
  },

  /**
   * ### liveby.user
   *
   * Return a user given the user's email or their sessionId.
   *
   */
  user: function (query) {
    return this._fetch(endpoints.user, query)
  },
  /**
   * ### liveby.factors( query ).then(…)
   *
   * Return a promise that will resolve to a match result for factors,
   * unordered.
   *
   * Parameters:
   *
   * - `query`: Only show factors matching the query object
   *
   * Example:
   *
   * ```js
   * var liveby = new LiveBy({version: 1})
   *
   * // Retrieve match results for the city of Lincoln
   * liveby.factors()
   *   .then(factors => console.log(factors))
   *   .catch(err => console.error(err))
   * ```
   *
   * See the [Factors schema] for a complete description of the properties
   * available on the factor object.
   *
   * This function automatically binds the method parameters for the factor
   * methods.
   *
   * [Factors schema]: https://github.com/LiveBy/API/blob/master/models/factors.js
   */
  factors: function (query) {
    var q = { filter: query }
    return this._fetch(endpoints.factors, q)
  },
  /**
   * ### liveby.geoid( geoid ).then(…)
   *
   * Return a promise that will resolve to a GeoID query result for `geoid`.
   *
   * Parameters:
   *
   * - `geoid`: A String or Number representing the GEOID to look up.
   *
   * Example:
   *
   * ```js
   * var liveby = new LiveBy({version: 1})
   *
   * // Retrieve and log the name of the location with GEOID 31
   * liveby.geoid('31').then(result => {
   *   console.log(result.data.NAME)
   *   // "Nebraska"
   * }, err => {
   *   console.error(err)
   * })
   * ```
   *
   * See the [Places schema] for a complete description of the properties available on the geoid result
   * object.
   */
  geoid: function (geoid) {
    return this._fetch(endpoints.geoid + '/' + geoid)
  },
  /**
   * ### liveby.geocode({ lat, lon }).then(…)
   *
   * Return a promise that will resolve to a geocode query result for `geoid`.
   *
   * Parameters:
   *
   * - `query`: An object containing a `lat` and `lon` property.
   *
   * Example:
   *
   * ```js
   * // TODO(ben@liveby.co): Document properties on the geocode result.
   * var liveby = new LiveBy({version: 1})
   * liveby.geocode({ lat:40.7693789, lon:-96.650090 }).then(result => {
   *   console.log(result.data)
   *   // {}
   * }, err => {
   *   console.error(err)
   * })
   * ```
   */
  geocode: function (query) {
    return this._fetch(endpoints.geocode, query)
  },
  /**
   * ### liveby.route({start, end})
   *
   * Parameters:
   *
   *  - `start`: Array of points(`[Lon, Lat]`) to start from
   *  - `end`: Array of points(`[Lon, Lat]`) to end
   *  Example:
   *
   * ```js
   * LiveBy.route({
   *   src:[
   *     [-96.67282104492188,40.82069462135467]
   *   ],
   *   dst:[
   *     [-96.7074966430664,40.791849155467695],
   *     [-96.62527084350586,40.75570967636433]
   *   ]
   * })
   *   .then((res) => {
   *     console.log(res.data.distance_table[0])
   *   }, err => {
   *     console.log(err)
   *   })
   * ```
   */
  route: function (query) {
    return this._fetch(endpoints.route, query)
  },
  /**
   * ### liveby.distance({src, dist}) || liveby.distance({loc})
   *
   * Parameters:
   *
   *  - `src`: Array of points(`[Lon, Lat]`) of the sources. Used with dst parameter to compute distance to those destinations
   *  - `dst`: Array of points(`[Lon, Lat]`) of the destinations. Used with the src parameter to compute distances to that source point
   *  - `loc`: Array of points(`[Lon, Lat]`) of points. Used to compute distances to all other points given.
   *
   *  Example:
   *
   * ```js
   * LiveBy.distance({
   *   src:[
   *     [-96.67282104492188,40.82069462135467]
   *   ],
   *   dst:[
   *     [-96.7074966430664,40.791849155467695],
   *     [-96.62527084350586,40.75570967636433]
   *   ]
   * })
   *   .then((res) => {
   *     console.log(res.data.distance_table[0])
   *   }, err => {
   *     console.log(err)
   *   })
   * ```
   */
  distance: function (query) {
    return this._fetch(endpoints.distance, query)
  },
  /**
   * ### liveby.propertiesAttributeAggregator
   * Parameters:
   *    Take a neighborhood to use its geometry and a status to select which properties you want returned.
   */

  propertiesAttributeAggregator: function (options) {
    var queryOptions = Object.assign({}, options)
    if (queryOptions.neighborhood) {
      queryOptions.neighborhood = {
        geometry: queryOptions.neighborhood.geometry
      }
    }
    var fetchOptions = {
      method: 'POST',
      body: queryOptions
    }
    return this._fetch(
      endpoints.propertiesAttributeAggregator,
      {},
      fetchOptions
    )
  },
  /**
   * ### liveby.proximityMatchScore(query: {})
   *
   *  Parameters:
   *   Whatever proximity matches you wish to calculate.
   */
  proximityMatchScore: function (query) {
    return this._fetch(endpoints.matchScore.proximity, query)
  },
  /**
   * ### liveby.place(query)
   *
   *  Parameters
   *
   *   - `query`: The query to identify the place.[https://github.com/LiveBy/API/blob/master/models/places.js] for more information
   */
  place: function (query) {
    return this._fetch(endpoints.places, { filter: query })
  },

  /**
   * ### liveby.locations(query)
   *
   *   Parameters
   *
   *  - `query`: query to send to foursquare
   */
  locations: function (query) {
    return this._fetch(endpoints.locations, { filter: query })
  },
  /**
   * ### liveby.locations(query)
   *
   *   Parameters
   *
   *  - `query`: query to send to foursquare
   */
  amenities: function (query) {
    return this._fetch(endpoints.amenities, { filter: query })
  },

  /**
   * ### liveby.sendLead(options)
   *
   *  Parameters
   *  - `options`: options that contain:
   *    - `name`
   *    - `email`
   *    - `neighborhood`
   *    - `url`
   *    - `brokerage`
   *    - `request`
   *    - `referrer`
   */
  sendLead: function sendLead(options, useVersion2 = false) {
    return this._fetch(
      'neighborhood-subscribe/subscribe' + (useVersion2 ? 'V2' : ''),
      options
    )
  },
  /**
   * ### liveby.shareMatch(options)
   *
   *   Parameters:
   *
   *   - `options`: An Object containing the following properties:
   *
   *     - `emails`: A String of comma seperated emails to send to
   *     - `preferences`: An Object of their preferences
   *     - `url`: A String of current URL
   */
  shareMatch: function (options) {
    return this._fetch(endpoints.shareMatch, options)
  },
  /**
   * ### liveby.shareNeighborhood(options)
   *
   *   Parameters:
   *
   *   - `options`: An Object containing the following properties:
   *     - `emails`: comma seperated emails to send to (String)
   *     - `neighborhood`: The `_id` of the neighborhood to share as a String
   *     - `message`: An optional message (String) to go along with the share
   *     - `brokerage`: The shortname of the brokerage (String) to share from
   *     - `place`: The `properties.id` (String) of the Place the neighborhood should be
   *        associated with
   *     - `origin`: A String containing the root domain this was shared from. This is
   *        usually `document.location.origin`.
   */
  shareNeighborhood: function (options) {
    return this._fetch(endpoints.shareNeighborhood, options)
  },
  /**
   * ### liveby.notify({action, state})
   *
   * Notify the API that the state of the user's preferences has changed.
   *
   * This may be used in the future to notify the server of additional
   * interactions or state changes.
   *
   * Parameters:
   *
   * - `payload`: An object with data to send to the server. This could
   *   contain state, event data, or pretty much anything.
   */
  notify: function (payload) {
    return this._fetch(
      endpoints.notify,
      {},
      {
        body: payload,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }
    )
  },
  /**
   * ### liveby.yelpBusinessesMap(neighborhood)
   *
   * Return Yelp API businesses for the neighborhood's area
   *
   * Parameters:
   * - `neighborhood`: An Object which is the neighborhood.
   * - `categories`: types of businesses to get
   * - `offset`: the page to get
   */
  yelpBusinessesMap: function (opts) {
    if (!opts.offset) opts.offset = 0
    return this._fetch(
      endpoints.yelpBusinessesMap,
      {},
      {
        method: 'POST',
        body: {
          lat: opts.lat,
          lng: opts.lng,
          geometry: opts.bbox,
          limit: 50,
          offset: opts.offset * 50,
          categories: opts.categories,
          language: opts.language,
          sort_by: opts.sort_by
        }
      }
    )
  },
  /**
   * ### liveby.yelpBusinesses(neighborhood)
   *
   * Return Yelp API businesses for the neighborhood's area
   *
   * Parameters:
   * - `neighborhood`: An Object which is the neighborhood.
   * - `categories`: types of businesses to get
   * - `offset`: the page to get
   */
  yelpBusinesses: function (opts) {
    if (!opts.offset) opts.offset = 0
    return this._fetch(
      endpoints.yelpBusinesses,
      {},
      {
        method: 'POST',
        body: {
          lat: opts.neighborhood.properties.INTPTLAT,
          lng: opts.neighborhood.properties.INTPTLON,
          geometry: opts.neighborhood.geometry,
          limit: 16,
          offset: opts.offset * 16,
          categories: opts.categories,
          language: opts.language,
          sort_by: opts.sort_by
        }
      }
    )
  },
  /**
   * ### liveby.mlsInfo(mls)
   *
   * Return MLS attribution information for an MLS.
   *
   * Parameters:
   * - `opts`: A pass-through object of query parameters to the MLS info API
   **/
  mlsInfo: function (opts) {
    return this._fetch(
      endpoints.mlsInfo,
      {},
      {
        // Use POST in case the MLS list is super long
        method: 'POST',
        body: opts
      }
    )
  },
  // Private: Return a promise that will fetch the URL, parse the JSON,
  // and return resolve with the response.
  _fetch: function (endpoint, query, options) {
    options = assign(
      {
        method: 'GET',
        credentials: 'same-origin'
      },
      options,
      {
        headers: assign(
          {
            'csrf-token': this.config.csrf
          },
          (options && options.headers) || {}
        )
      }
    )
    if (options.method.toUpperCase() !== 'GET') {
      options.body = options.body ? JSON.stringify(options.body) : ''
    }

    var url =
      this.root +
      endpoint +
      (query ? '?' + stringify(query, { arrayFormat: 'brackets' }) : '')

    return fetch(url, options).then(parseJSON)
  }
}

function parseJSON(response) {
  var contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('json') !== -1) {
    return response.json()
  } else {
    return response
  }
}
