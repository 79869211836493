const t = (s) => 'liveby-theme-' + s

// A label for a simple statistic and it's comparative data point
export const marketTrendsStatPrimary = t('market-trends-stat-primary')
export const marketTrendsStatSecondary = t('market-trends-stat-secondary')

// A bar in the bar stat
export const marketTrendsBarStatBar = t('market-trends-bar-stat-bar')

// The neighborhood line
export const marketTrendsChartStatNeighborhoodLine = t(
  'market-trends-chart-stat-neighborhood-line'
)
export const marketTrendsChartStatTooltipNeighborhoodLabel = t(
  'market-trends-chart-stat-tooltip-neighborhood-label'
)

// The city line
export const marketTrendsChartStatCityLine = t(
  'market-trends-chart-stat-city-line'
)
export const marketTrendsChartStatTooltipCityLabel = t(
  'market-trends-chart-stat-tooltip-city-label'
)

// The zip code line
export const marketTrendsChartStatZipCodeLine = t(
  'market-trends-chart-stat-zip-code-line'
)
export const marketTrendsChartStatTooltipZipCodeLabel = t(
  'market-trends-chart-stat-tooltip-zip-code-label'
)

export const schoolsRatingStar = t('schools-rating-star')
export const schoolsRatingStarEmpty = t('schools-rating-star-empty')

// The amenities ("Living in ______") category buttons (e.g. Dining, Shopping)
export const amenityCategoryButton = t('amenity-category-button')

export const section = t('section')
export const sectionHeading = t('section-heading')
export const sectionSubheading = t('section-subheading')
export const sectionContent = t('section-content')

export const customContent = t('custom-content')
export const customHtml = t('custom-html')
export const customText = t('custom-text')
